import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import "lib-flexible/flexible.js";
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";
import locale from "element-plus/lib/locale/lang/zh-cn";
import $ from "jquery";
import axios from "axios";
import router from "./router";

const app = createApp(App);
app.use(store).use(router).use(ElementPlus, { locale }).mount("#app");
app.config.globalProperties.axios = axios;
