import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("../views/Layout.vue"),
    redirect: "/home",
    name: "home",
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("../views/home/Index.vue"),
      },
      {
        path: "/search/:keyword",
        name: "search",
        component: () => import("../views/home/Search.vue"),
      },
      {
        path: "/approach",
        name: "desc",
        redirect: "/approach/desc",
        children: [
          {
            path: "/approach/desc",
            name: "desc",
            component: () => import("../views/approach/Description.vue"),
          },
          {
            path: "/approach/evolution",
            name: "evolution",
            component: () => import("../views/approach/Evolution.vue"),
          },
          {
            path: "/approach/honor",
            name: "honor",
            component: () => import("../views/approach/Honor.vue"),
          },
          // {
          //   path: "/approach/certification",
          //   name: "certification",
          //   component: () => import("../views/approach/Certification.vue"),
          // },
          {
            path: "/approach/companyCulture",
            name: "companyCulture",
            component: () => import("../views/approach/CompanyCulture.vue"),
          },
          {
            path: "/approach/talent",
            name: "talent",
            component: () => import("../views/approach/Talent.vue"),
          },
          {
            path: "/approach/talent/detail/:id",
            name: "talentDetail",
            component: () => import("../views/approach/TalentDetail.vue"),
          },
        ],
      },
      {
        path: "/news",
        name: "news",
        redirect: "/news/enNews",
        children: [
          {
            path: "/news/enNews",
            name: "enNews",
            component: () => import("../views/news/enNews/Index.vue"),
          },
          {
            path: "/news/enNews/detail/:id",
            name: "enNewsDetail",
            component: () => import("../views/news/enNews/Detail.vue"),
          },
          {
            path: "/news/cpNews",
            name: "cpNews",
            component: () => import("../views/news/cpNews/Index.vue"),
          },
          {
            path: "/news/cpNews/detail/:id",
            name: "cpNewsDetail",
            component: () => import("../views/news/cpNews/Detail.vue"),
          },
          {
            path: "/news/partyNews",
            name: "partyNews",
            component: () => import("../views/news/partyNews/Index.vue"),
          },
          {
            path: "/news/partyNews/detail/:id",
            name: "partyNewsDetail",
            component: () => import("../views/news/partyNews/Detail.vue"),
          },
          {
            path: "/news/bulletin",
            name: "bulletin",
            component: () => import("../views/news/bulletin/Index.vue"),
          },
          {
            path: "/news/bulletin/detail/:id",
            name: "bulletinDetail",
            component: () => import("../views/news/bulletin/Detail.vue"),
          },
        ],
      },
      {
        path: "/projects",
        name: "projects",
        component: () => import("../views/projects/Index.vue"),
      },
      {
        path: "/projects/water-pollution",
        name: "waterPollution",
        component: () => import("../views/projects/waterPollution/Index.vue"),
        // redirect: "/projects/water-pollution/town",
        // children: [
        //   {
        //     path: "/projects/water-pollution/town",
        //     name: "town",
        //     component: () =>
        //       import("../views/projects/waterPollution/town/Index.vue"),
        //   },
        //   {
        //     path: "/projects/water-pollution/town/detail/:id",
        //     name: "townDetail",
        //     component: () =>
        //       import("../views/projects/waterPollution/town/Detail.vue"),
        //   },
        //   {
        //     path: "/projects/water-pollution/electroplate",
        //     name: "electroplate",
        //     component: () =>
        //       import("../views/projects/waterPollution/electroplate/Index.vue"),
        //   },
        //   {
        //     path: "/projects/water-pollution/electroplate/detail/:id",
        //     name: "electroplateDetail",
        //     component: () =>
        //       import(
        //         "../views/projects/waterPollution/electroplate/Detail.vue"
        //       ),
        //   },
        //   {
        //     path: "/projects/water-pollution/printing",
        //     name: "printing",
        //     component: () =>
        //       import("../views/projects/waterPollution/printing/Index.vue"),
        //   },
        //   {
        //     path: "/projects/water-pollution/printing/detail/:id",
        //     name: "printingDetail",
        //     component: () =>
        //       import("../views/projects/waterPollution/printing/Detail.vue"),
        //   },
        //   {
        //     path: "/projects/water-pollution/foodstuff",
        //     name: "foodstuff",
        //     component: () =>
        //       import("../views/projects/waterPollution/foodstuff/Index.vue"),
        //   },
        //   {
        //     path: "/projects/water-pollution/foodstuff/detail/:id",
        //     name: "foodstuffDetail",
        //     component: () =>
        //       import("../views/projects/waterPollution/foodstuff/Detail.vue"),
        //   },
        //   {
        //     path: "/projects/water-pollution/tan",
        //     name: "tan",
        //     component: () =>
        //       import("../views/projects/waterPollution/tan/Index.vue"),
        //   },
        //   {
        //     path: "/projects/water-pollution/tan/detail/:id",
        //     name: "tanDetail",
        //     component: () =>
        //       import("../views/projects/waterPollution/tan/Detail.vue"),
        //   },
        //   {
        //     path: "/projects/water-pollution/pharmacy",
        //     name: "pharmacy",
        //     component: () =>
        //       import("../views/projects/waterPollution/pharmacy/Index.vue"),
        //   },
        //   {
        //     path: "/projects/water-pollution/pharmacy/detail/:id",
        //     name: "pharmacyDetail",
        //     component: () =>
        //       import("../views/projects/waterPollution/pharmacy/Detail.vue"),
        //   },
        //   {
        //     path: "/projects/water-pollution/livestock",
        //     name: "livestock",
        //     component: () =>
        //       import("../views/projects/waterPollution/livestock/Index.vue"),
        //   },
        //   {
        //     path: "/projects/water-pollution/livestock/detail/:id",
        //     name: "livestockDetail",
        //     component: () =>
        //       import("../views/projects/waterPollution/livestock/Detail.vue"),
        //   },
        //   {
        //     path: "/projects/water-pollution/others",
        //     name: "others",
        //     component: () =>
        //       import("../views/projects/waterPollution/others/Index.vue"),
        //   },
        //   {
        //     path: "/projects/water-pollution/others/detail/:id",
        //     name: "othersDetail",
        //     component: () =>
        //       import("../views/projects/waterPollution/others/Detail.vue"),
        //   },
        // ],
      },
      {
        path: "/projects/water-pollution/detail/:id",
        name: "waterPollutionDetail",
        component: () => import("../views/projects/waterPollution/Detail.vue"),
      },
      {
        path: "/projects/air-pollution",
        name: "airPollution",
        component: () => import("../views/projects/airPollution/Index.vue"),
      },
      {
        path: "/projects/air-pollution/detail/:id",
        name: "airPollutionDetail",
        component: () => import("../views/projects/airPollution/Detail.vue"),
      },
      {
        path: "/projects/noise-pollution",
        name: "noisePollution",
        component: () => import("../views/projects/noisePollution/Index.vue"),
      },
      {
        path: "/projects/noise-pollution/detail/:id",
        name: "noisePollutionDetail",
        component: () => import("../views/projects/noisePollution/Detail.vue"),
      },
      {
        path: "/projects/solid-waste",
        name: "solidWaste",
        component: () => import("../views/projects/solidWaste/Index.vue"),
      },
      {
        path: "/projects/solid-waste/detail/:id",
        name: "solidWasteDetail",
        component: () => import("../views/projects/solidWaste/Detail.vue"),
      },
      {
        path: "/projects/fix-pollution",
        name: "fixPollution",
        // redirect: "/projects/fix-pollution/lake",
        component: () => import("../views/projects/fixPollution/Index.vue"),
        // children: [
        //   {
        //     path: "/projects/fix-pollution/lake",
        //     name: "lake",
        //     component: () =>
        //       import("../views/projects/fixPollution/lake/Index.vue"),
        //   },
        //   {
        //     path: "/projects/fix-pollution/lake/detail/:id",
        //     name: "lakeDetail",
        //     component: () =>
        //       import("../views/projects/fixPollution/lake/Detail.vue"),
        //   },
        //   {
        //     path: "/projects/fix-pollution/sewage-plant",
        //     name: "sewagePlant",
        //     component: () =>
        //       import("../views/projects/fixPollution/sewagePlant/Index.vue"),
        //   },
        //   {
        //     path: "/projects/fix-pollution/sewage-plant/detail/:id",
        //     name: "sewagePlantDetail",
        //     component: () =>
        //       import("../views/projects/fixPollution/sewagePlant/Detail.vue"),
        //   },
        //   {
        //     path: "/projects/fix-pollution/river",
        //     name: "river",
        //     component: () =>
        //       import("../views/projects/fixPollution/river/Index.vue"),
        //   },
        //   {
        //     path: "/projects/fix-pollution/river/detail/:id",
        //     name: "riverDetail",
        //     component: () =>
        //       import("../views/projects/fixPollution/river/Detail.vue"),
        //   },
        // ],
      },
      {
        path: "/projects/fix-pollution/detail/:id",
        name: "fixPollutionDetail",
        component: () => import("../views/projects/fixPollution/Detail.vue"),
      },
      {
        path: "/projects/village",
        name: "village",
        component: () => import("../views/projects/village/Index.vue"),
      },
      {
        path: "/projects/village/detail/:id",
        name: "villageDetail",
        component: () => import("../views/projects/village/Detail.vue"),
      },
      {
        path: "/projects/mine",
        name: "mine",
        component: () => import("../views/projects/mine/Index.vue"),
      },
      {
        path: "/projects/mine/detail/:id",
        name: "mineDetail",
        component: () => import("../views/projects/mine/Detail.vue"),
      },
      {
        path: "/projects/facility",
        name: "facility",
        component: () => import("../views/projects/facility/Index.vue"),
      },
      {
        path: "/projects/facility/detail/:id",
        name: "facilityDetail",
        component: () => import("../views/projects/facility/Detail.vue"),
      },

      {
        path: "/consult",
        name: "consult",
        component: () => import("../views/consult/Index.vue"),
      },
      {
        path: "/consult/source",
        name: "source",
        component: () => import("../views/consult/source/Index.vue"),
      },
      {
        path: "/consult/source/detail/:id",
        name: "sourceDetail",
        component: () => import("../views/consult/source/Detail.vue"),
      },
      {
        path: "/consult/outlet",
        name: "outlet",
        component: () => import("../views/consult/outlet/Index.vue"),
      },
      {
        path: "/consult/outlet/detail/:id",
        name: "outletDetail",
        component: () => import("../views/consult/outlet/Detail.vue"),
      },
      {
        path: "/consult/water-evaluation",
        name: "waterEvaluation",
        component: () => import("../views/consult/waterEvaluation/Index.vue"),
      },
      {
        path: "/consult/water-evaluation/detail/:id",
        name: "waterEvaluationDetail",
        component: () => import("../views/consult/waterEvaluation/Detail.vue"),
      },
      {
        path: "/consult/counseling",
        name: "counseling",
        component: () => import("../views/consult/counseling/Index.vue"),
      },
      {
        path: "/consult/counseling/detail/:id",
        name: "counselingDetail",
        component: () => import("../views/consult/counseling/Detail.vue"),
      },
      {
        path: "/consult/risk-management",
        name: "riskManagement",
        component: () => import("../views/consult/riskManagement/Index.vue"),
      },
      {
        path: "/consult/risk-management/detail/:id",
        name: "riskManagementDetail",
        component: () => import("../views/consult/riskManagement/Detail.vue"),
      },
      {
        path: "/consult/risk-evaluation",
        name: "riskEvaluation",
        component: () => import("../views/consult/riskEvaluation/Index.vue"),
      },
      {
        path: "/consult/risk-evaluation/detail/:id",
        name: "riskEvaluationDetail",
        component: () => import("../views/consult/riskEvaluation/Detail.vue"),
      },
      {
        path: "/equipment",
        name: "equipment",
        component: () => import("../views/equipment/Index.vue"),
      },
      {
        path: "/equipment/detail/:id",
        name: "equipmentDetail",
        component: () => import("../views/equipment/Detail.vue"),
      },
      {
        path: "/case",
        name: "case",
        component: () => import("../views/case/Index.vue"),
      },
      {
        path: "/case/detail/:id",
        name: "caseDetail",
        component: () => import("../views/case/Detail.vue"),
      },
      {
        path: "/join-in",
        name: "joinIn",
        redirect: "/join-in/contact",
        children: [
          {
            path: "/join-in/contact",
            name: "contact",
            component: () => import("../views/joinIn/contact.vue"),
          },
          {
            path: "/join-in/human-resources",
            name: "humanResources",
            component: () => import("../views/joinIn/humanResources.vue"),
          },
          {
            path: "/join-in/human-resources/detail/:id",
            name: "humanResourcesDetail",
            component: () => import("../views/joinIn/humanResourcesDetail.vue"),
          },
        ],
      },
      {
        path: "/videos",
        name: "videos",
        component: () => import("../views/videos/Index.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
