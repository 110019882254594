<template>
  <router-view />
</template>

<style lang="less">
@import url("@/assets/common/css/common.less");
.container {
  max-width: 1200px;
  position: relative;
  margin: 0 auto;
}
#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
.clearFix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
</style>
