import { createStore } from "vuex";

export default createStore({
  state: { isRouterAlive: true },
  getters: {},
  mutations: {
    setIsRouterAlive(state, data) {
      state.isRouterAlive = data;
    },
  },
  actions: {
    setIsRouterAlive(context, data) {
      context.commit("setIsRouterAlive", data);
    },
  },
  modules: {},
});
